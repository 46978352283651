<template>
  <div class="container-fluid pb-rewardlist">
    <Header />
    <Breadcrumbs :title="currenttitle" :parent="parent" :current="current" />
    <div class="mx-auto content-outer-container">
      <div class="mx-auto mainContainer">
        <div class="titleContainer">
          <div class="title">
            <div class="textTitle">
              <img
                class="mr-2"
                style="vertical-align: sub"
                src="@/assets/images/gift.svg"
                alt=""
              />Reward List
            </div>
          </div>
          <div class="search">
            <div class="searchContainer">
              <a-input
                type="text"
                class="searchInput"
                placeholder="(Rewards name, Baht/Point, Baht/Stamp)"
                v-model="filterNameEmail"
                @keyup.enter="onEnterInput($event)"
                autocomplete="off"
              />
              <img
                @click="onEnterInput($event)"
                :src="require('@/assets/images/Search.png')"
                alt=""
              />
            </div>
            <div>
              Campaign Mode: Point
              <span>
                <img
                  class="page-setting-icon"
                  :src="require('@/assets/images/plan-reward-setting.png')"
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
        <div class="filterContainer nopad mt-4">
          <div :class="['filterMain', showFilterMain ? 'active' : '']">
            <div class="filter" @click="toggle('showFilterMain')">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item)"
                  >
                    <CheckBox
                      :label="item"
                      :valuee="item"
                      :check="filter[subFilter].value"
                      type="filter"
                    />
                  </div>
                  <div
                    v-if="filter[subFilter].type === 'text'"
                    class="filterList input"
                  >
                    <input
                      type="text"
                      :placeholder="item"
                      v-model="filter[subFilter].value"
                    />
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter()">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="getUserListByFilter()">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in Object.keys(allFilter)"
            :key="'filter' + index"
          >
            {{ allFilter[item]
            }}<img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
          <div class="ml-auto">
            <button
              @click="toggleUserModal(true, 'create')"
              class="searchBtn d-flex"
            >
              Create New
            </button>
          </div>
        </div>
        <a-table
          row-key="UserID"
          :columns="columnsDetail"
          :pagination="{ defaultPageSize: 5 }"
          :bordered="false"
          class="mt-3"
        >
          <!-- <template slot="name" slot-scope="text, record">
            {{ record.FirstName }}{{ ' ' }}{{ record.LastName }}
          </template> -->
        </a-table>
      </div>
    </div>
    <UserModal
      :isUserModal="isUserModal"
      :toggle="toggleUserModal"
      :brandId="brandId"
      :terminaId="terminaId"
      :toggleDetail="toggleUserDetailModal"
      :mode="mode"
      :form="editData"
    />
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsUserPermission from '@/core/UserPermission/service/BzbsUserPermission'
import Vue from 'vue'
import Header from '@/components/Layout/Header'
import CheckBox from './components/CheckBox.vue'
import UserModal from './components/UserModal'
import Breadcrumbs from '@/module/Settings/3rdParty/components/Breadcrumbs.vue'
const columnsDetail = [
  {
    title: 'Rewards',
    dataIndex: 'Rewards',
    key: 'Rewards',
    scopedSlots: { customRender: 'Rewards' },
  },
  {
    title: 'Period',
    dataIndex: 'Period',
    key: 'Period',
    scopedSlots: { customRender: 'Period' },
  },
  {
    title: 'Baht / Point',
    dataIndex: 'Baht_Point',
    key: 'Baht_Point',
    scopedSlots: { customRender: 'Baht_Point' },
  },
]
export default {
  name: 'UserPermission',
  mixins: [Mixin],
  components: {
    Header,
    CheckBox,
    UserModal,
    Breadcrumbs,
  },
  data() {
    return {
      columnsDetail,
      currenttitle: 'Settings',
      current: 'User permission',
      parent: null,
      allUser: 0,
      storeName: '',
      brandId: '',
      terminaId: '',
      isShowmore: {
        admin: false,
        operator: false,
        staff: false,
      },
      filterNameEmail: '',
      filter: {
        status: {
          value: null,
          input: ['active', 'inactive'],
          label: 'Status',
          type: 'checkbox',
        },
        branchName: {
          value: null,
          input: ['Branch Name'],
          label: 'Branch Name',
          type: 'text',
        },
        branchId: {
          value: null,
          input: ['Branch ID'],
          label: 'Branch ID',
          type: 'text',
        },
      },
      subFilter: 'status',
      showFilterMain: false,
      roleData: {
        admin: {
          header: 'Admin',
          key: 'admin',
          subHeader: this.permissionAdmin,
          userList: [],
        },
        operator: {
          header: 'Operator',
          key: 'operator',
          subHeader: this.permissionOperator,
          userList: [],
        },
        staff: {
          header: 'Staff',
          key: 'staff',
          subHeader: this.permissionStaff,
          userList: [],
        },
      },
      permissionList: {},
      isUserDetailModal: false,
      UserDetail: {},
      userTitle: '',
      isUserModal: false,
      mode: 'create',
      editData: {
        active: true,
        role: 'admin',
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        email: '',
        contactNumber: '',
        branchIds: [],
      },
      bus: new Vue(),
      branchList: [],
    }
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  computed: {
    allFilter() {
      return Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})
    },
    permissionadmin() {
      return Object.keys(this.permissionListAdmin)
        .filter(i => !this.permissionListAdmin[i])
        .map(i => this.lbl['user-permissions-' + i])
        .join(', ')
    },
    permissionoperator() {
      return Object.keys(this.permissionListOperator)
        .filter(i => !this.permissionListOperator[i])
        .map(i => this.lbl['user-permissions-' + i])
        .join(', ')
    },
    permissionstaff() {
      return Object.keys(this.permissionListStaff)
        .filter(i => !this.permissionListStaff[i])
        .map(i => this.lbl['user-permissions-' + i])
        .join(', ')
    },
  },
  created() {
    this.handleFooter(true)
    this.getUserListByFilter()
    this.getOperatorPermission()
    this.getBranchList()
  },
  methods: {
    setLocale() {
      this.currenttitle = this.lbl['main-menu-setting']
      this.current = 'Reward List'
      this.parent = null
    },
    logdata() {
      console.log(this.allFilter)
    },
    toggle(data) {
      this[data] = !this[data]
    },
    toggleUserDetailModal(type, data, title) {
      if (data) this.UserDetail = data
      this.userTitle = title
      if (title === 'User Created!') this.getUserListByFilter()
      this.isUserDetailModal = type
    },
    toggleUserModal(type, mode, data) {
      this.mode = mode
      if (mode === 'create' && type) {
        this.editData.branchIds = this.branchList.map(item => item.ID)
      }
      if (mode === 'edit' && type) {
        this.editData = {
          active: data.Active,
          role: data.CrmPlusRole,
          firstName: data.FirstName,
          lastName: data.LastName,
          userName: data.UserName,
          userNameApi: data.UserName,
          password: '',
          confirmPassword: '',
          email: data.Email,
          contactNumber: data.ContactNumber || '',
          branchIds: [...data.Branches],
          oldRole: data.Role,
          PartitionKey: data.PartitionKey || '',
          PosRole: data.PosRole || '',
        }
      }
      if (!type) {
        this.editData = {
          active: true,
          role: 'admin',
          firstName: '',
          lastName: '',
          userName: '',
          password: '',
          confirmPassword: '',
          email: '',
          contactNumber: '',
          branchIds: [],
        }
        this.getUserListByFilter()
      }
      this.isUserModal = type
    },
    onEnterInput() {
      this.getUserListByFilter()
    },
    showMore(type, data) {
      this.isShowmore[type] = data
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    sortUserList(type, order) {
      if (order === 'name')
        this.roleData[type].userList.sort((a, b) =>
          a.FirstName.localeCompare(b.FirstName),
        )
      if (order === 'email')
        this.roleData[type].userList.sort((a, b) =>
          a.Email.localeCompare(b.Email),
        )
    },
    totalList(type) {
      return this.roleData[type].userList.length
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'active' && item === 'active') ||
        (this.filter[this.subFilter].value === 'inactive' &&
          item === 'inactive')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.getUserListByFilter()
    },
    resetFilter() {
      this.filter.status.value = ''
      this.filter.branchName.value = ''
      this.filter.branchId.value = ''
      this.getUserListByFilter()
    },
    clearUserList() {
      Object.keys(this.roleData).forEach(item => {
        this.roleData[item].userList = []
      })
    },
    getBranchList() {
      BzbsProfile.getCRMPlusProfile()
        .then(res => {
          this.branchList = res.data.Stores
        })
        .catch(err => console.log(err))
    },
    getUserListByFilter() {
      this.showFilterMain = false
      this.allUser = 0
      this.clearUserList()
      let active = ''
      if (this.filter.status.value === 'active') active = true
      if (this.filter.status.value === 'inactive') active = false
      const Lfilter = {
        active,
        branchId: this.filter.branchId.value,
        branchName: this.filter.branchName.value,
      }
      this.handleLoading(true)
      Promise.all([
        BzbsUserPermission.getCRMPlusUserList(
          Lfilter,
          'staff',
          this.filterNameEmail,
        ),
        BzbsUserPermission.getCRMPlusUserList(
          Lfilter,
          'admin',
          this.filterNameEmail,
        ),
        BzbsUserPermission.getCRMPlusUserList(
          Lfilter,
          'operator',
          this.filterNameEmail,
        ),
        BzbsUserPermission.getCRMPlusStaffUserList(
          Lfilter,
          this.filterNameEmail,
        ),
      ])
        .then(res => {
          let flatData = res
            .map(item => item.data)
            .flat()
            .filter(i => !i.Deleted)
          this.allUser = flatData.length
          flatData.forEach(item => {
            if (item.PosRole === 'Owner') {
              this.roleData[item.CrmPlusRole].userList.unshift(item)
            } else {
              this.roleData[item.CrmPlusRole].userList.push(item)
            }
          })
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.handleLoading(false)
        })
    },
    getOperatorPermission() {
      BzbsUserPermission.getCRMPlusStoreInfo()
        .then(res => {
          this.storeName = res.data.BusinessName || '-'
          this.brandId = res.data.EwalletBrandId || '-'
          this.terminaId = Vue.bzbsConfig.businessRule.default.terminalId
          this.permissionListOperator = {
            dashboard: res.data.HideDashboard,
            accountsetup: res.data.HideAccountSetup,
            linesetup: res.data.HideLineSetup,
            manageuser: res.data.HideManageUser,
            theme: res.data.HideThemeSetting,
            importbatch: res.data.IsAllowImportBatch,
            memberdetail: res.data.HideMemberDetail,
            memberlevel: res.data.HasUserlevel,
            pointsetuptab: res.data.HidePointSetupTab,
            privatecycondition: res.data.HideMemberPrivacyMenu,
            download: res.data.HideDownloadMenu,
          }
        })
        .catch(err => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/RewardList/style/rewardlist.scss';
</style>
